import { useEffect, useState } from "react";
import { API_BASE_URL } from "../../config.tsx";
import { getNameErrors } from "../helpers/helpers.tsx";
import { getCountries, getHeadquarters } from "../helpers/requests.tsx";

const ChangeGroupDataModal = ({
  setShowChangeDataModal,
  groupId,
  groupName,
  groupCountryId,
  groupCountryName,
  headquarterId,
  showSelectHQ = false,
  handleChangeData,
}) => {
  const [newGroupName, setNewGroupName] = useState("");
  const [newGroupCountryId, setNewGroupCountryId] = useState("");
  const [newGroupCountryName, setNewGroupCountryName] = useState("");
  const [newHeadquarterId, setNewHeadquarterId] = useState("");
  const [newHeadquarterName, setNewHeadquarterName] = useState("");

  const [saveDataSubmitted, setSaveDataSubmitted] = useState(false);

  const [countries, setCountries] = useState<any[]>([]);
  const [headquarters, setHeadquarters] = useState<any[]>([]);

  useEffect(() => {
    setNewGroupName(groupName);
    setNewGroupCountryId(groupCountryId);
    setNewGroupCountryName(groupCountryName);
    setNewHeadquarterId(headquarterId);

    const fetchData = async () => {
      const countryList = await getCountries();
      setCountries(countryList);

      const headquarterList = await getHeadquarters();
      setHeadquarters(headquarterList);
    };

    fetchData();
  }, []);

  const updateGroupData = async (
    groupId: string | null,
    groupName: string,
    groupCountryId: string,
    headquarterId: string
  ) => {
    try {
      const response = await fetch(`${API_BASE_URL}/updateGroupData`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          groupId,
          groupName,
          groupCountryId,
          headquarterId,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to update group data");
      }
    } catch (error) {
      console.error("Error update group data:", error);
      throw new Error("An error occurred while update group data");
    }
  };

  const handleSaveClick = async () => {
    setSaveDataSubmitted(true);

    if (getNameErrors(newGroupName)) return;

    await updateGroupData(
      groupId,
      newGroupName,
      newGroupCountryId,
      newHeadquarterId
    );

    handleChangeData(
      newGroupName,
      newGroupCountryId,
      newGroupCountryName,
      newHeadquarterId,
      newHeadquarterName
    );
  };

  return (
    <div className="confirmation-modal">
      <div className="modal-content">
        <div style={{ textAlign: "center", fontSize: "1.3em" }}>
          Edit hospital
        </div>
        <div className="mb-3" style={{ marginTop: "2vh" }}>
          <label
            htmlFor="nameInput"
            style={{
              marginLeft: "20px",
              color: "#404040",
              fontWeight: "bold",
            }}
          >
            Hospital name
          </label>
          <input
            type="text"
            id="nameInput"
            className="form-control"
            value={newGroupName}
            onChange={(e) => {
              setNewGroupName(e.target.value);
              setSaveDataSubmitted(false);
            }}
            style={{
              backgroundColor: "#CCCCCC",
              borderRadius: "20px",
              color: "#404040",
              height: "5vh",
              maxHeight: "40px",
              fontSize: "1em",
              paddingLeft: "20px",
            }}
          />
          <label
            htmlFor="nameInput"
            style={{
              marginLeft: "20px",
              color: "#A63939",
              fontSize: "0.8em",
              visibility:
                saveDataSubmitted && getNameErrors(newGroupName)
                  ? "visible"
                  : "hidden",
            }}
          >
            {getNameErrors(newGroupName)}
          </label>
        </div>

        <div className="mb-3" style={{ marginTop: "2vh" }}>
          <label
            htmlFor="countrySelect"
            style={{
              marginLeft: "20px",
              color: "#404040",
              fontWeight: "bold",
            }}
          >
            Country
          </label>
          <select
            id="countrySelect"
            className="form-control"
            style={{
              backgroundColor: "#CCCCCC",
              borderRadius: "20px",
              color: "#404040",
              height: "5vh",
              maxHeight: "40px",
              fontSize: "1em",
              paddingLeft: "20px",
            }}
            value={newGroupCountryId}
            onChange={(e) => {
              const selectedCountryId = e.target.value;
              const selectedCountry = countries.find(
                (country) => country.id === selectedCountryId
              );
              if (selectedCountry) {
                setNewGroupCountryId(selectedCountryId);
                setNewGroupCountryName(selectedCountry.name);
                setSaveDataSubmitted(false);
              }
            }}
          >
            {countries.map((country: any) => (
              <option key={country.id} value={country.id}>
                {country.name}
              </option>
            ))}
          </select>
        </div>

        {showSelectHQ && (
          <div className="mb-3" style={{ marginTop: "2vh" }}>
            <label
              htmlFor="headquarterSelect"
              style={{
                marginLeft: "20px",
                color: "#404040",
                fontWeight: "bold",
              }}
            >
              Headquarter
            </label>
            <select
              id="headquarterSelect"
              className="form-control"
              style={{
                backgroundColor: "#CCCCCC",
                borderRadius: "20px",
                color: "#404040",
                height: "5vh",
                maxHeight: "40px",
                fontSize: "1em",
                paddingLeft: "20px",
              }}
              value={newHeadquarterId}
              onChange={(e) => {
                const selectedHeadquarterId = e.target.value;
                const selectedHeadquarter = headquarters.find(
                  (headquarter) => headquarter.id === selectedHeadquarterId
                );

                if (selectedHeadquarter || selectedHeadquarterId === "") {
                  setNewHeadquarterId(selectedHeadquarterId);
                  setNewHeadquarterName(
                    selectedHeadquarterId === "" ? "" : selectedHeadquarter.name
                  );
                  setSaveDataSubmitted(false);
                }
              }}
            >
              <option value=""></option>
              {headquarters.map((headquarter: any) => (
                <option key={headquarter.id} value={headquarter.id}>
                  {headquarter.name}
                </option>
              ))}
            </select>
          </div>
        )}

        <div className="button-container">
          <button
            type="button"
            className="buttonCancel btn btn-primary"
            onClick={() => {
              setShowChangeDataModal(false);
              setSaveDataSubmitted(false);
            }}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-primary buttonAcceptGreen"
            onClick={() => handleSaveClick()}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChangeGroupDataModal;

import React, { useEffect, useState } from "react";
import { API_BASE_URL } from "../../config.tsx";
import { getGroupNameErrors } from "../helpers/helpers.tsx";
import { getCountries, getHeadquarters } from "../helpers/requests.tsx";

const CreateGroupModal = ({
  setShowCreateGroupModal,
  headquarterId,
  handleCreateGroup,
  showSelectHQ = false,
}) => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedHQ, setSelectedHQ] = useState("");
  const [countries, setCountries] = useState<any[]>([]);
  const [headquarters, setHeadquarters] = useState<any[]>([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const countryList = await getCountries();
    setCountries(countryList);

    const headquarterList = await getHeadquarters();
    setHeadquarters(headquarterList);
  };

  const createGroup = async (
    name: string,
    country: string,
    headquarterId: string | undefined
  ) => {
    try {
      const response = await fetch(`${API_BASE_URL}/createGroup`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ name, country, headquarterId }),
      });

      if (!response.ok) {
        throw new Error("Failed to create group");
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error creating group:", error);
      throw new Error("An error occurred while create group");
    }
  };

  const handleCreateGroupSubmit = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();

    setFormSubmitted(true);

    if (getGroupNameErrors(selectedGroup) || selectedCountry === "") {
      return;
    }

    if (showSelectHQ && !headquarterId) headquarterId = selectedHQ;

    const newGroup = await createGroup(
      selectedGroup,
      selectedCountry,
      headquarterId
    );

    handleCreateGroup(newGroup);
  };

  return (
    <div className="confirmation-modal">
      <div className="modal-content">
        <div style={{ textAlign: "center", fontSize: "1.3em" }}>
          Create a new hospital
        </div>
        <form onSubmit={handleCreateGroupSubmit} className="m-4">
          <div className="mb-3" style={{ marginTop: "2vh" }}>
            <label
              htmlFor="groupNameInput"
              style={{
                marginLeft: "20px",
                color: "#404040",
                fontWeight: "bold",
              }}
            >
              Hospital Name
            </label>
            <input
              type="text"
              className="form-control"
              id="groupNameInput"
              placeholder="Hospital"
              value={selectedGroup}
              onChange={(e) => {
                setSelectedGroup(e.target.value);
                setFormSubmitted(false);
              }}
              style={{
                backgroundColor: "#CCCCCC",
                borderRadius: "20px",
                color: "#404040",
                height: "5vh",
                maxHeight: "40px",
                fontSize: "1em",
                paddingLeft: "20px",
              }}
            />
            <label
              htmlFor="groupNameInput"
              style={{
                marginLeft: "20px",
                color: "#A63939",
                fontSize: "0.8em",
                visibility:
                  formSubmitted && getGroupNameErrors(selectedGroup)
                    ? "visible"
                    : "hidden",
              }}
            >
              {getGroupNameErrors(selectedGroup)}
            </label>
          </div>

          <div className="mb-3" style={{ marginTop: "2vh" }}>
            <label
              htmlFor="countrySelect"
              style={{
                marginLeft: "20px",
                color: "#404040",
                fontWeight: "bold",
              }}
            >
              Country
            </label>
            <select
              id="countrySelect"
              className="form-control"
              style={{
                backgroundColor: "#CCCCCC",
                borderRadius: "20px",
                color: "#404040",
                height: "5vh",
                maxHeight: "40px",
                fontSize: "1em",
                paddingLeft: "20px",
              }}
              value={selectedCountry}
              onChange={(e) => {
                setSelectedCountry(e.target.value);
                setFormSubmitted(false);
              }}
            >
              <option value=""></option>
              {countries.map((country: any) => (
                <option key={country.id} value={country.id}>
                  {country.name}
                </option>
              ))}
            </select>
            <label
              htmlFor="countrySelect"
              style={{
                marginLeft: "20px",
                color: "#A63939",
                fontSize: "0.8em",
                visibility:
                  formSubmitted && selectedCountry === ""
                    ? "visible"
                    : "hidden",
              }}
            >
              Country is required
            </label>
          </div>

          {showSelectHQ && (
            <div className="mb-3" style={{ marginTop: "2vh" }}>
              <label
                htmlFor="headquarterSelect"
                style={{
                  marginLeft: "20px",
                  color: "#404040",
                  fontWeight: "bold",
                }}
              >
                Headquarter
              </label>
              <select
                id="headquarterSelect"
                className="form-control"
                style={{
                  backgroundColor: "#CCCCCC",
                  borderRadius: "20px",
                  color: "#404040",
                  height: "5vh",
                  maxHeight: "40px",
                  fontSize: "1em",
                  paddingLeft: "20px",
                }}
                value={selectedHQ}
                onChange={(e) => {
                  setSelectedHQ(e.target.value);
                  setFormSubmitted(false);
                }}
              >
                <option value=""></option>
                {headquarters.map((headquarter: any) => (
                  <option key={headquarter.id} value={headquarter.id}>
                    {headquarter.name}
                  </option>
                ))}
              </select>
            </div>
          )}

          <div className="button-container">
            <button
              type="button"
              className="buttonCancel btn btn-primary"
              onClick={() => setShowCreateGroupModal(false)}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn btn-primary buttonAcceptGreen"
              disabled={formSubmitted}
            >
              Create
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateGroupModal;

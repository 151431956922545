import React, { useState, useEffect } from "react";
import { useAuth } from "../firebase/auth.tsx";
import { useNavigate } from "react-router-dom";

import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";

const Login: React.FC = () => {
  const { login, logout, userRole, user } = useAuth();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [loginResult, setLoginResult] = useState("");

  useEffect(() => {
    // Clean local storage
    if (!userRole && user) {
      logout();
    }
  }, []);

  const loginUser = async (event: React.FormEvent<HTMLFormElement>) => {
    setFormSubmitted(true);
    setLoginResult("");

    event.preventDefault();
    try {
      await login(email, password);
    } catch (error) {
      setLoginResult("Email or password is incorrect");
    }
  };

  const handleSignUpClick = () => {
    navigate("/register");
  };

  const handleResetPasswordClick = () => {
    navigate("/resetPassword");
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "calc(100vh - 40px)",
        paddingTop: "40px",
        maxWidth: "1400px",
        margin: "0 auto",
      }}
    >
      <form
        onSubmit={loginUser}
        className="w-25 m-4"
        style={{ minHeight: "50vh" }}
      >
        <img
          src="/images/VR_ICU_Logo_Main.png"
          alt="Logo"
          className="mb-3"
          style={{
            width: "100%",
            maxWidth: "100%",
          }}
        />
        <div className="mb-3" style={{ marginTop: "20px" }}>
          <label
            htmlFor="emailInput"
            style={{
              marginLeft: "20px",
              color: "#404040",
            }}
          >
            Email address
          </label>
          <input
            type="email"
            className="form-control"
            id="emailInput"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setFormSubmitted(false);
            }}
            style={{
              backgroundColor: "#CCCCCC",
              borderRadius: "20px",
              color: "#404040",
              height: "5vh",
              maxHeight: "40px",
              fontSize: "1em",
              paddingLeft: "20px",
            }}
          />
        </div>
        <div className="mb-3">
          <label
            htmlFor="emailInput"
            style={{
              marginLeft: "20px",
              color: "#404040",
            }}
          >
            Password
          </label>
          <input
            type="password"
            className="form-control"
            id="passwordInput"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
              setFormSubmitted(false);
            }}
            style={{
              backgroundColor: "#CCCCCC",
              borderRadius: "20px",
              color: "#404040",
              height: "5vh",
              maxHeight: "40px",
              fontSize: "1em",
              paddingLeft: "20px",
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "1rem",
          }}
        >
          <div style={{ height: "2em" }}>
            <label
              style={{
                marginTop: "5px",
                marginBottom: "5px",
                color: "#A63939",
                fontSize: "0.8em",
                visibility:
                  formSubmitted && loginResult !== "" ? "visible" : "hidden",
              }}
            >
              {loginResult}
            </label>
          </div>
          <button
            type="submit"
            className="btn btn-primary mb-2"
            style={{
              width: "40%",
              height: "5vh",
              maxHeight: "40px",
              maxWidth: "100%",
              borderRadius: "20px",
              marginTop: "10px",
              backgroundColor: "#39A686",
              borderColor: "#39A686",
            }}
          >
            Login
          </button>
          <button
            type="button"
            className="btn btn-primary mb-2"
            onClick={handleSignUpClick}
            style={{
              width: "40%",
              maxWidth: "100%",
              height: "5vh",
              maxHeight: "40px",
              borderRadius: "20px",
              background: "none",
              borderColor: "transparent",
              color: "#404040",
            }}
          >
            Sign Up
          </button>
          <button
            type="button"
            className="btn btn-primary mb-2"
            onClick={handleResetPasswordClick}
            style={{
              marginTop: "-1.5vh",
              background: "none",
              borderColor: "transparent",
              color: "#404040",
              fontFamily: "VirtualLabQuickSandMedium",
            }}
          >
            Forgot your password?
          </button>
        </div>
      </form>
    </div>
  );
};

export default Login;

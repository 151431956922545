import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../firebase/auth.tsx";
import PageMenu from "./pageMenu.tsx";

export const ProtectedRoute = ({ children, fullScreenMode }) => {
  const {
    userId,
    userRole,
    firstName,
    lastName,
    user,
    headquarterId,
    groupId,
    logout,
  } = useAuth();

  if (!userRole) {
    return <Navigate to="/login" />;
  }

  return (
    <>
      <PageMenu
        userRole={userRole}
        userId={userId}
        firstName={firstName}
        lastName={lastName}
        email={user}
        headquarterId={headquarterId}
        groupId={groupId}
        logout={logout}
        fullScreenMode={fullScreenMode}
      />
      {children}
    </>
  );
};

import { API_BASE_URL } from "../../config.tsx";

export const getNameErrors = (name: string) => {
  const pattern = /^[a-zA-Z0-9\-'\s]+$/;

  if (name.trim() === "") {
    return "Name is required";
  }

  if (!pattern.test(name)) {
    return "Invalid characters in the name";
  }

  return "";
};

export const getGroupNameErrors = (name: string) => {
  const pattern = /^[a-zA-Z0-9\-'\s]+$/;

  if (name.trim() === "") {
    return "Hospital name is required";
  }

  if (!pattern.test(name)) {
    return "Invalid characters in the hospital name";
  }

  return "";
};

export const getHQNameErrors = (name: string) => {
  const pattern = /^[a-zA-Z0-9\-'\s]+$/;

  if (name.trim() === "") {
    return "Headquarter name is required";
  }

  if (!pattern.test(name)) {
    return "Invalid characters in the headquarter name";
  }

  return "";
};

export const isEmailValid = (email: string) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

export const getPasswordErrors = (
  password: string,
  confirmPassword: string
) => {
  if (password.length < 6) {
    return "Password should be at least 6 characters long";
  }
  if (password.length > 4096) {
    return "Password should not exceed 4096 characters";
  }
  if (!/\d/.test(password)) {
    return "Password should contain numbers";
  }
  if (password !== confirmPassword) {
    return "Those passwords didn’t match. Try again";
  }
  return "";
};

export const getFirstNameErrors = (firstName: string) => {
  const pattern = /^[a-zA-Z0-9\-'\s]+$/;

  if (firstName.trim() === "") {
    return "First name is required";
  }

  if (!pattern.test(firstName)) {
    return "Invalid characters in the first name";
  }

  return "";
};

export const getLastNameErrors = (lastName: string) => {
  const pattern = /^[a-zA-Z0-9\-'\s]+$/;

  if (lastName.trim() === "") {
    return "Last name is required";
  }

  if (!pattern.test(lastName)) {
    return "Invalid characters in the last name";
  }

  return "";
};

export const correctUserRoleName = (userRole) => {
  switch (userRole) {
    case "admin":
      return "Global administrator";
    case "headquarterAdmin":
      return "Headquarter administrator";
    case "groupAdmin":
      return "Hospital administrator";
    default:
      return userRole;
  }
};

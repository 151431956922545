import React, { useEffect, useState } from "react";
import { useAuth } from "../../firebase/auth.tsx";
import { API_BASE_URL } from "../../config.tsx";
import { getHQNameErrors } from "../helpers/helpers.tsx";

const CreateHQModal = ({ setShowCreateHQModal, handleCreateHQ }) => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [selectedHQName, setSelectedHQName] = useState("");

  const createHeadquarter = async (name: string) => {
    try {
      const response = await fetch(`${API_BASE_URL}/createHQ`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ name }),
      });

      if (!response.ok) {
        throw new Error("Failed to create HQ");
      }

      const userData = await response.json();
      return userData;
    } catch (error) {
      console.error("Error creating HQ:", error);
      throw new Error("An error occurred while create HQ");
    }
  };

  const handleCreateHQSubmit = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();

    setFormSubmitted(true);

    if (getHQNameErrors(selectedHQName)) {
      return;
    }

    const newHeadquarter = await createHeadquarter(selectedHQName);

    handleCreateHQ(newHeadquarter);
  };

  return (
    <div className="confirmation-modal">
      <div className="modal-content">
        <div
          style={{ textAlign: "center", fontSize: "1.3em", marginTop: "2vh" }}
        >
          Create a new headquarter
        </div>
        <form onSubmit={handleCreateHQSubmit} className="m-4">
          <div className="mb-3" style={{ marginTop: "2vh" }}>
            <label
              htmlFor="HQNameInput"
              style={{
                marginLeft: "20px",
                color: "#404040",
                fontWeight: "bold",
              }}
            >
              Headquarter name
            </label>
            <input
              type="text"
              className="form-control"
              id="HQNameInput"
              placeholder="Headquarter"
              value={selectedHQName}
              onChange={(e) => {
                setSelectedHQName(e.target.value);
                setFormSubmitted(false);
              }}
              style={{
                backgroundColor: "#CCCCCC",
                borderRadius: "20px",
                color: "#404040",
                height: "5vh",
                maxHeight: "40px",
                fontSize: "1em",
                paddingLeft: "20px",
              }}
            />
            <label
              htmlFor="HQNameInput"
              style={{
                marginLeft: "20px",
                color: "#A63939",
                fontSize: "0.8em",
                visibility:
                  formSubmitted && getHQNameErrors(selectedHQName)
                    ? "visible"
                    : "hidden",
              }}
            >
              {getHQNameErrors(selectedHQName)}
            </label>
          </div>

          <div className="button-container">
            <button
              type="button"
              className="buttonCancel btn btn-primary"
              onClick={() => setShowCreateHQModal(false)}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn btn-primary buttonAcceptGreen"
              disabled={formSubmitted}
            >
              Create
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateHQModal;

import { API_BASE_URL } from "../../config.tsx";

export const checkForAdmin = async (userId: string) => {
  try {
    const response = await fetch(`${API_BASE_URL}/checkForAdmin`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ userId }),
    });

    if (!response.ok) {
      throw new Error("Failed to check for admin");
    }

    const userData = await response.json();
    return userData;
  } catch (error) {
    console.error("Error check for Admin:", error);
    throw new Error("An error occurred while check for admin");
  }
};

export const getCountries = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}/getCountries`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error("Failed to get countries");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error getting countries:", error);
    throw new Error("An error occurred while get countries");
  }
};

export const getCountryDataByGroupId = async (groupId) => {
  try {
    const response = await fetch(`${API_BASE_URL}/getCountryDataByGroupId`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ groupId }),
    });

    if (!response.ok) {
      throw new Error("Failed to get country data");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error getting country data:", error);
    throw new Error("An error occurred while get country data");
  }
};

export const getHeadquarterNameById = async (headquarterId) => {
  try {
    const response = await fetch(`${API_BASE_URL}/getHeadquarterNameById`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ headquarterId }),
    });

    if (!response.ok) {
      throw new Error("Failed to get headquarter name");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error getting headquarter name:", error);
    throw new Error("An error occurred while get headquarter name");
  }
};

export const getHeadquarterNameByGroupId = async (groupId) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/getHeadquarterNameByGroupId`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ groupId }),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to get headquarter name");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error getting headquarter name:", error);
    throw new Error("An error occurred while get headquarter name");
  }
};

export const getGroupNameById = async (groupId) => {
  try {
    const response = await fetch(`${API_BASE_URL}/getGroupNameById`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ groupId }),
    });

    if (!response.ok) {
      throw new Error("Failed to get group name");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error getting group name:", error);
    throw new Error("An error occurred while get group name");
  }
};

export const getGroups = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}/getGroups`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error("Failed to get groups");
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Error getting groups:", error);
    throw new Error("An error occurred while get groups");
  }
};

export const getHeadquarters = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}/getHeadquarters`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error("Failed to get headquarter");
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Error getting headquarter:", error);
    throw new Error("An error occurred while get headquarter");
  }
};

export const getGroupsInHeadquarter = async (headquarterId) => {
  try {
    const response = await fetch(`${API_BASE_URL}/getGroupsInHeadquarter`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ headquarterId }),
    });

    if (!response.ok) {
      throw new Error("Failed to get groups");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error getting groups:", error);
    throw new Error("An error occurred while get groups");
  }
};

export const getUserDataById = async (userId) => {
  try {
    const response = await fetch(`${API_BASE_URL}/getUserDataById`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ userId }),
    });

    if (!response.ok) {
      throw new Error("Failed to get user data");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error getting user data:", error);
    throw new Error("An error occurred while get user data");
  }
};

import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../firebase/auth.tsx";
import { API_BASE_URL } from "../config.tsx";
import "../App.css";
import styles from "../styles/headquarterList.module.css";
//import "bootstrap/dist/css/bootstrap.min.css";
import ConfirmDeleteHQModal from "./modals/confirmDeleteHQModal.tsx";
import ChangeHQDataModal from "./modals/changeHQDataModal.tsx";
import ConfirmHideModal from "./modals/confirmHideModal.tsx";
import ConfirmPermissionModal from "./modals/confirmPermissionModal.tsx";

interface SelectedTraining {
  id?: string;
  name?: string;
  entityId?: string;
  permission?: boolean;
  manufacturerId?: string;
  isHidden?: boolean;
}

const HeadquarterList = ({ headquarters, setHeadquarters }) => {
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const { userId } = useAuth();
  const [isLoadingHQ, setIsLoadingHQ] = useState(false);
  const [isLoadingTrainings, setIsLoadingTrainings] = useState(false);
  const [trainingsList, setTrainingsList] = useState<any[]>([]);
  const [comingSoonManufacturers, setComingSoonManufacturers] = useState<any[]>(
    []
  );
  const [selectedHQId, setSelectedHQId] = useState("");
  const [selectedHQName, setSelectedHQName] = useState("");
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [editingHQ, setEditingHQ] = useState<{
    [key: string]: boolean;
  }>({});
  const [showChangeDataModal, setShowChangeDataModal] = useState(false);

  const [visibility, setVisibility] = useState({});
  const [showConfirmHideModal, setShowConfirmHideModal] = useState(false);
  const [showConfirmPermissionModal, setShowConfirmPermissionModal] =
    useState(false);
  const [selectedManufacturerId, setSelectedManufacturerId] = useState("");
  const [selectedManufacturerName, setSelectedManufacturerName] = useState("");
  const [selectedTraining, setSelectedTraining] = useState<SelectedTraining>(
    {}
  );

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoadingHQ(true);
      await getHeadquarters();
      setIsLoadingHQ(false);
    };

    fetchData();

    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOptionsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);

      setHeadquarters([]);
      setSelectedHQId("");
      setSelectedHQName("");
      setEditingHQ({});
      setTrainingsList([]);
    };
  }, [setHeadquarters]);

  const fetchHQTrainingsList = async (headquarterId: string) => {
    setIsLoadingTrainings(true);
    try {
      const response = await fetch(
        `${API_BASE_URL}/getTrainingsListWithPermissions`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            entityId: headquarterId,
            entityType: "headquarter",
            showAll: true,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to get headquarter trainings list");
      }

      const trainings = await response.json();

      setTrainingsList(trainings);

      // Initialize visibility state
      const initialVisibility = {};
      trainings.forEach((training) => {
        initialVisibility[training.manufacturerId] =
          initialVisibility[training.manufacturerId] || training.isHidden;
      });
      setVisibility(initialVisibility);
    } catch (error) {
      console.error("Error get headquarter trainings list:", error);
      throw new Error("An error occurred while get headquarter trainings list");
    } finally {
      setIsLoadingTrainings(false);
    }
  };

  const fetchComingSoonManufacturers = async (headquarterId: string) => {
    setIsLoadingTrainings(true);

    try {
      const response = await fetch(
        `${API_BASE_URL}/getComingSoonManufacturers`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            entityId: headquarterId,
            entityType: "headquarter",
            showAll: true,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to get coming soon manufacturers");
      }

      const manufacturers = await response.json();

      setComingSoonManufacturers(manufacturers);

      setVisibility((prevVisibility) => {
        const updatedVisibility = { ...prevVisibility };
        manufacturers.forEach((manufacturer) => {
          updatedVisibility[manufacturer.id] = manufacturer.isHidden;
        });
        return updatedVisibility;
      });
    } catch (error) {
      console.error("Error get coming soon manufacturers:", error);
      throw new Error("An error occurred while get coming soon manufacturers");
    } finally {
      setIsLoadingTrainings(false);
    }
  };

  const getHeadquarters = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/getHeadquarters`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Failed to get headquarter");
      }

      const data = await response.json();
      setHeadquarters(data);
    } catch (error) {
      console.error("Error getting headquarter:", error);
      throw new Error("An error occurred while get headquarter");
    }
  };

  const updateHQPermission = async (
    headquarterId: string,
    trainingId: string,
    isChecked: boolean,
    isHidden: boolean = false
  ) => {
    try {
      const response = await fetch(`${API_BASE_URL}/updateTrainingPermission`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          entityId: headquarterId,
          entityType: "headquarter",
          trainingId,
          isChecked,
          isHidden,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to update headquarter training permission");
      }
    } catch (error) {
      console.error("Error update headquarter training permission:", error);
      throw new Error(
        "An error occurred while update headquarter training permission"
      );
    }
  };

  const toggleDropdown = () => {
    setIsOptionsOpen(!isOptionsOpen);
  };

  const handleViewClick = async (
    headquarterId: string,
    headquarterName: string
  ) => {
    setIsLoadingHQ(true);
    navigate(`/groupList/headquarter/${headquarterId}`);
  };

  const handleEditClick = async (
    headquarterId: string,
    headquarterName: string
  ) => {
    setEditingHQ((prevState) => {
      const newState = { ...prevState };
      Object.keys(newState).forEach((key) => {
        newState[key] = false;
      });
      newState[headquarterId] = true;
      return newState;
    });

    setSelectedHQId(headquarterId);
    setSelectedHQName(headquarterName);

    await fetchHQTrainingsList(headquarterId);
    await fetchComingSoonManufacturers(headquarterId);
  };

  const handleDeleteHQ = () => {
    setHeadquarters((prevHQ) =>
      prevHQ.filter((headquarter) => headquarter.id !== selectedHQId)
    );
    setSelectedHQId("");
    setSelectedHQName("");
    setEditingHQ({});
    setTrainingsList([]);
    setShowConfirmDeleteModal(false);
  };

  const handleChangeData = async (newHQName: string) => {
    setHeadquarters((prevHQ) =>
      prevHQ.map((headquarter) => {
        if (headquarter.id === selectedHQId) {
          return {
            ...headquarter,
            name: newHQName,
          };
        }
        return headquarter;
      })
    );

    setSelectedHQName(newHQName);
    setShowChangeDataModal(false);
  };

  const renderConfirmDeleteHQModal = () => {
    return (
      <ConfirmDeleteHQModal
        setShowConfirmDeleteHQModal={setShowConfirmDeleteModal}
        headquarterId={selectedHQId}
        headquarterName={selectedHQName}
        handleDeleteHQ={handleDeleteHQ}
      />
    );
  };

  const renderChangeDataModal = () => {
    return (
      <ChangeHQDataModal
        setShowChangeDataModal={setShowChangeDataModal}
        headquarterId={selectedHQId}
        headquarterName={selectedHQName}
        handleChangeData={handleChangeData}
      />
    );
  };

  const renderConfirmPermissionModal = () => {
    return (
      <ConfirmPermissionModal
        setShowConfirmPermissionModal={setShowConfirmPermissionModal}
        trainingId={selectedTraining.id}
        trainingName={selectedTraining.name}
        targetId={selectedTraining.entityId}
        targetName={selectedHQName}
        permission={selectedTraining.permission}
        handlePermissionChange={handlePermissionChange}
      />
    );
  };

  const renderConfirmHideModal = () => {
    const isHidden = visibility[selectedManufacturerId];
    if (isHidden) handleHideClick(selectedManufacturerId);
    else {
      return (
        <ConfirmHideModal
          setShowConfirmHideModal={setShowConfirmHideModal}
          manufacturerId={selectedManufacturerId}
          manufacturerName={selectedManufacturerName}
          headquarterView={true}
          handleHideClick={handleHideClick}
        />
      );
    }
  };

  const handlePermissionChange = async (
    trainingId: string,
    targetId: string,
    permission: boolean
  ) => {
    await updateHQPermission(targetId, trainingId, permission);
    setTrainingsList(
      trainingsList.map((training) => {
        if (training.id === trainingId) {
          return { ...training, permission: permission };
        }

        return training;
      })
    );
    setSelectedTraining({});
  };

  const handleHideClick = (manufacturerId) => {
    setShowConfirmHideModal(false);
    const isHidden = visibility[manufacturerId];
    if (
      comingSoonManufacturers.some(
        (comingSoonManufacturer) => comingSoonManufacturer.id === manufacturerId
      )
    ) {
      // Update permissions to false for manufacturer
      const updatedComingSoonManufacturers = comingSoonManufacturers.map(
        (comingSoonManufacturer) => {
          if (comingSoonManufacturer.id === manufacturerId) {
            updateHQPermission(
              selectedHQId,
              comingSoonManufacturer.id,
              true,
              !isHidden
            );
            return { ...comingSoonManufacturer, isHidden: !isHidden };
          }
          return comingSoonManufacturer;
        }
      );

      setComingSoonManufacturers(updatedComingSoonManufacturers);
    } else {
      // Update permissions to false for all trainings under this manufacturer
      const updatedTrainings = trainingsList.map((training) => {
        if (training.manufacturerId === manufacturerId) {
          updateHQPermission(training.entityId, training.id, false, !isHidden);
          return { ...training, permission: false, isHidden: !isHidden };
        }
        return training;
      });

      setTrainingsList(updatedTrainings);
    }

    setVisibility((prevVisibility) => ({
      ...prevVisibility,
      [manufacturerId]: !prevVisibility[manufacturerId],
    }));
  };

  const groupTrainingsByManufacturer = (trainings) => {
    const groupedTrainings = {};
    trainings.forEach((training) => {
      const {
        id,
        name,
        manufacturerId,
        manufacturerName,
        permission,
        entityId,
        isHidden,
      } = training;
      if (!groupedTrainings[manufacturerId]) {
        groupedTrainings[manufacturerId] = {
          manufacturerName: manufacturerName,
          trainings: [],
        };
      }
      groupedTrainings[manufacturerId].trainings.push({
        id: id,
        name: name,
        permission: permission,
        entityId: entityId,
        isHidden: isHidden,
        manufacturerName: manufacturerName,
      });
    });

    return groupedTrainings;
  };

  const renderGroupedTrainings = (groupedTrainings: {
    [manufacturerId: string]: {
      manufacturerName: string;
      trainings: {
        id: string;
        entityId: string;
        name: string;
        permission: boolean;
        isHidden: boolean;
      }[];
    };
  }) => {
    return (
      <>
        {Object.entries(groupedTrainings).map(
          ([manufacturerId, { manufacturerName, trainings }]) => {
            const isAnyTrainingHidden = trainings.some(
              (training) => training.isHidden
            );
            return (
              <div key={manufacturerId} className={styles.trainingsGroup}>
                <label
                  className={styles.trainingsGroupTitle}
                  style={{ color: isAnyTrainingHidden ? "gray" : "#39a686" }}
                >
                  {manufacturerName}
                </label>
                <span
                  onClick={() => {
                    setSelectedManufacturerId(manufacturerId);
                    setSelectedManufacturerName(manufacturerName);
                    setShowConfirmHideModal(true);
                  }}
                  style={{
                    marginLeft: "1vh",
                    fontSize: "0.7em",
                    cursor: "pointer",
                  }}
                >
                  {isAnyTrainingHidden ? "show" : "hide"}
                </span>
                <ul>
                  {trainings.map((training) => (
                    <li
                      key={training.id}
                      className="form-check custom-checkbox"
                      style={{ color: training.isHidden ? "gray" : "#212529" }}
                    >
                      <label className="custom-checkbox-label">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="agreeCheckbox"
                          checked={training.permission}
                          disabled={training.isHidden}
                          onChange={(e) => {
                            setSelectedTraining((prevInfo) => ({
                              ...prevInfo,
                              id: training.id,
                              name: training.name,
                              entityId: training.entityId,
                              permission: training.permission,
                            }));
                            setShowConfirmPermissionModal(true);
                          }}
                        />
                        <span className="checkmark"></span>
                        <span className="form-check-label">
                          {training.name}
                        </span>
                      </label>
                    </li>
                  ))}
                </ul>
              </div>
            );
          }
        )}
        {comingSoonManufacturers.length > 0 && (
          <label
            className={styles.trainingsGroupTitle}
            style={{ color: "#39a686" }}
          >
            Coming soon:
          </label>
        )}
        {comingSoonManufacturers.map(({ id, name, isHidden }) => {
          return (
            <div key={id} className={styles.trainingsGroup}>
              <label
                className={styles.trainingsGroupTitle}
                style={{ color: isHidden ? "gray" : "#212529" }}
              >
                {name}
              </label>
              <span
                onClick={() => {
                  setSelectedManufacturerId(id);
                  setSelectedManufacturerName(name);
                  setShowConfirmHideModal(true);
                }}
                style={{
                  marginLeft: "1vh",
                  fontSize: "0.7em",
                  cursor: "pointer",
                }}
              >
                {isHidden ? "show" : "hide"}
              </span>
            </div>
          );
        })}
      </>
    );
  };

  return (
    <div className={styles.adminContainer}>
      {isLoadingHQ ? (
        <p className={styles.loading}>Loading...</p>
      ) : (
        <>
          {/* Left side */}
          <div className={styles.leftContainer}>
            {/* Separate table header - don't find other way to avoid overlaping header on scroll */}
            <div className={styles.tableHeader}>
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Headquarter</th>
                    <th scope="col" className={styles.editButtonWrapper}></th>
                    <th scope="col" className={styles.editButtonWrapper}></th>
                  </tr>
                </thead>
              </table>
            </div>

            {/* Table with restricted height and scrollbar */}
            <div className={styles.tableContent}>
              <div className={`container-fluid ${styles.containerFluid}`}>
                <table className="table">
                  <tbody>
                    {headquarters.map((headquarter: any, index: number) => (
                      <tr
                        key={headquarter.id}
                        style={{
                          backgroundColor:
                            index % 2 === 0 ? "#D4E5E2" : "transparent",
                        }}
                      >
                        <td>
                          {" "}
                          <img
                            src="/images/Icon_HQ.png"
                            style={{
                              width: "2.5vh",
                              minWidth: "10px",
                              marginLeft: "0.3vh",
                              marginRight: "1vh",
                            }}
                          />
                          {headquarter.name}
                        </td>
                        <td className={styles.editButtonWrapper}>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() =>
                              handleViewClick(headquarter.id, headquarter.name)
                            }
                            style={{
                              backgroundColor: "#39A686",
                              borderColor: "#39A686",
                            }}
                          >
                            View
                          </button>
                        </td>
                        <td className={styles.editButtonWrapper}>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() =>
                              handleEditClick(headquarter.id, headquarter.name)
                            }
                            style={{
                              backgroundColor: editingHQ[headquarter.id]
                                ? "#404040"
                                : "#39A686",
                              borderColor: editingHQ[headquarter.id]
                                ? "#404040"
                                : "#39A686",
                            }}
                          >
                            Details
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {/* Right side */}
          <div className={styles.rightContainer}>
            {selectedHQId ? (
              <>
                <div className={styles.containerHeaderWrapper}>
                  <div className={styles.containerHeader}>
                    <div className={styles.userDataWrapper}>
                      <div className={styles.userDataName}>
                        <img
                          src="/images/Icon_HQ.png"
                          style={{
                            width: "5vh",
                            minWidth: "25px",
                            marginRight: "15px",
                          }}
                        />
                        {selectedHQName}
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    <div className="dropdown" ref={dropdownRef}>
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={toggleDropdown}
                        style={{
                          width: "13vh",
                          minWidth: "60px",
                          backgroundColor: "#39A686",
                          borderColor: "#39A686",
                          borderRadius: "10px",
                          fontSize: "1em",
                          fontFamily: "VirtualLabQuickSandLight",
                          fontWeight: "bold",
                          paddingRight: "30px",
                        }}
                      >
                        <span
                          style={{
                            marginRight: "1.8vh",
                          }}
                        >
                          &#9013;
                        </span>
                        Options
                      </button>
                      {isOptionsOpen && (
                        <div className={styles.dropdownMenu}>
                          <button
                            type="button"
                            onClick={() => {
                              setIsOptionsOpen(false);
                              setShowChangeDataModal(true);
                            }}
                            className="btn"
                          >
                            <img src="/images/Icon_Edit.png" />
                            Edit Headquarter
                          </button>

                          <button
                            type="button"
                            onClick={() => {
                              setIsOptionsOpen(false);
                              setShowConfirmDeleteModal(true);
                            }}
                            className="btn"
                          >
                            <img src="/images/Icon_Delete.png" />
                            Delete Headquarter
                          </button>
                        </div>
                      )}
                    </div>
                  </div>

                  <hr />

                  <p className={styles.containerHeaderTitle}>
                    Training permision
                  </p>
                  <p className={styles.containerHeaderDescription}>
                    Set Headquarter access to these trainings in the VR
                    application
                  </p>
                </div>

                <div className={`${styles.trainingsContainer} thin-scrollbar`}>
                  {isLoadingTrainings ? (
                    <p>Loading...</p>
                  ) : (
                    renderGroupedTrainings(
                      groupTrainingsByManufacturer(trainingsList)
                    )
                  )}
                </div>
              </>
            ) : (
              <p className={styles.selectUser}>Select Headquarter to edit</p>
            )}
          </div>
        </>
      )}
      <div className={styles.adminContainer}>
        {(showConfirmDeleteModal && renderConfirmDeleteHQModal()) ||
          (showChangeDataModal && renderChangeDataModal()) ||
          (showConfirmHideModal && renderConfirmHideModal()) ||
          (showConfirmPermissionModal && renderConfirmPermissionModal())}
      </div>
    </div>
  );
};
export default HeadquarterList;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../config.tsx";

import "../App.css";

const ResetPassword: React.FC = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [resetPasswordError, setResetPasswordError] = useState(false);
  const [resetPasswordSuccess, setResetPasswordSuccess] = useState(false);

  const resetPassword = async (email) => {
    try {
      const response = await fetch(`${API_BASE_URL}/resetPassword`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });

      const result = await response.json();
      return result;
    } catch (error) {
      console.error("Error while reset password:", error);
      throw new Error("An error occurred while reset password");
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setFormSubmitted(true);

    if (!isEmailValid()) {
      return;
    }

    const result = await resetPassword(email);
    result ? setResetPasswordSuccess(true) : setResetPasswordError(true);
  };

  const handleBackClick = () => {
    navigate("/login");
  };

  const isEmailValid = () => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const renderResetPasswordSuccess = () => {
    return (
      <>
        <div
          style={{
            textAlign: "center",
            marginTop: "2vh",
            fontSize: "1.8vh",
          }}
        >
          Success
        </div>
        <div
          style={{
            textAlign: "center",
            marginTop: "2vh",
            fontSize: "1.2vh",
            fontFamily: "VirtualLabQuickSandMedium",
            fontWeight: "bold",
          }}
        >
          An email has been sent to {email} with instructions on how to finish
          resetting your password.
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "3vh",
          }}
        >
          <button
            type="button"
            className="btn btn-primary mb-2"
            onClick={handleBackClick}
            style={{
              width: "35%",
              height: "5vh",
              maxHeight: "40px",
              maxWidth: "100%",
              borderRadius: "20px",
              marginTop: "10px",
              backgroundColor: "#39A686",
              borderColor: "#39A686",
              fontSize: "1.2vh",
            }}
          >
            Return to login
          </button>
        </div>
      </>
    );
  };

  const renderResetPasswordError = () => {
    return (
      <>
        <div
          style={{
            textAlign: "center",
            margin: "7vh 0",
            fontSize: "1.2vh",
            fontFamily: "VirtualLabQuickSandMedium",
            fontWeight: "bold",
          }}
        >
          There was an unspecified error. Please try again.
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "3vh",
          }}
        >
          <button
            type="button"
            className="btn btn-primary mb-2"
            onClick={handleBackClick}
            style={{
              width: "35%",
              height: "5vh",
              maxHeight: "40px",
              maxWidth: "100%",
              borderRadius: "20px",
              marginTop: "10px",
              backgroundColor: "#39A686",
              borderColor: "#39A686",
              fontSize: "1.2vh",
            }}
          >
            Return to login
          </button>
        </div>
      </>
    );
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "calc(100vh - 40px)",
        paddingTop: "40px",
        maxWidth: "1400px",
        margin: "0 auto",
      }}
    >
      <div className="m-4" style={{ minHeight: "50vh", textAlign: "center" }}>
        <img
          src="/images/VR_ICU_Logo_Main.png"
          alt="Logo"
          className="mb-3"
          style={{
            width: "36.7vh",
          }}
        />
        {!resetPasswordError && !resetPasswordSuccess && (
          <>
            <div
              style={{
                textAlign: "center",
                marginTop: "2vh",
                fontSize: "1.8vh",
              }}
            >
              Reset your password
            </div>
            <form onSubmit={handleSubmit} className="m-4">
              <div
                className="mb-3"
                style={{ marginTop: "3vh", textAlign: "left" }}
              >
                <label
                  htmlFor="emailInput"
                  style={{
                    marginLeft: "2vh",
                    color: "#404040",
                    fontSize: "1.2vh",
                  }}
                >
                  Email
                </label>
                <input
                  className="form-control"
                  id="emailInput"
                  placeholder="example@site.com"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setFormSubmitted(false);
                  }}
                  style={{
                    backgroundColor: "#CCCCCC",
                    borderRadius: "20px",
                    color: "#404040",
                    height: "5vh",
                    maxHeight: "40px",
                    fontSize: "1.2vh",
                    paddingLeft: "2vh",
                  }}
                />
                <label
                  htmlFor="emailInput"
                  style={{
                    marginLeft: "20px",
                    color: "#A63939",
                    fontSize: "0.8em",
                    visibility:
                      formSubmitted && !isEmailValid() ? "visible" : "hidden",
                  }}
                >
                  This email is invalid
                </label>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: "3vh",
                }}
              >
                <button
                  type="submit"
                  className="btn btn-primary mb-2"
                  style={{
                    width: "45%",
                    height: "5vh",
                    maxHeight: "40px",
                    maxWidth: "100%",
                    borderRadius: "20px",
                    marginTop: "10px",
                    backgroundColor: "#39A686",
                    borderColor: "#39A686",
                    fontSize: "1.2vh",
                  }}
                  disabled={formSubmitted}
                >
                  Reset password
                </button>
                <button
                  type="button"
                  className="btn btn-primary mb-2"
                  onClick={handleBackClick}
                  style={{
                    width: "60%",
                    maxWidth: "100%",
                    borderRadius: "20px",
                    marginTop: "10px",
                    background: "none",
                    borderColor: "transparent",
                    color: "#404040",
                    fontSize: "1.2vh",
                  }}
                >
                  Back to login
                </button>
              </div>
            </form>
          </>
        )}
        {resetPasswordError && renderResetPasswordError()}
        {resetPasswordSuccess && renderResetPasswordSuccess()}
      </div>
    </div>
  );
};

export default ResetPassword;

import { useEffect, useState } from "react";
import { API_BASE_URL } from "../../config.tsx";
import { getFirstNameErrors, getLastNameErrors } from "../helpers/helpers.tsx";
import {
  getGroups,
  getHeadquarters,
  getGroupsInHeadquarter,
  getCountries,
} from "../helpers/requests.tsx";
import { User, UserRole } from "../helpers/interfaces.tsx";

type ChangeUserDataModal = {
  setShowChangeDataModal: React.Dispatch<React.SetStateAction<boolean>>;
  user: User;
  handleChangeData: (updatedUser: User) => void;
  adminView?: boolean;
  headquarterView?: boolean;
  groupView?: boolean;
};

const ChangeUserDataModal: React.FC<ChangeUserDataModal> = ({
  setShowChangeDataModal,
  user,
  handleChangeData,
  adminView = false,
  headquarterView = false,
  groupView = false,
}) => {
  const [newFirstName, setNewFirstName] = useState("");
  const [newLastName, setNewLastName] = useState("");
  const [newGroupId, setNewGroupId] = useState("");
  const [groupName, setGroupName] = useState("");
  const [groupCountry, setGroupCountry] = useState("");
  const [newHeadquarterId, setNewHeadquarterId] = useState("");
  const [headquarterName, setHeadquarterName] = useState("");
  const [newRole, setNewRole] = useState<UserRole>(UserRole.Guest);

  const [groups, setGroups] = useState<any[]>([]);
  const [headquarters, setHeadquarters] = useState<any[]>([]);
  const [countries, setCountries] = useState<any[]>([]);

  const [saveDataSubmitted, setSaveDataSubmitted] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      let groupsList = [];
      if (adminView) {
        groupsList = await getGroups();
        setGroups(groupsList);

        const headquarterList = await getHeadquarters();
        setHeadquarters(headquarterList);
      } else if (headquarterView) {
        groupsList = await getGroupsInHeadquarter(user.headquarterId);
        setGroups(groupsList);
      }

      const countryList = await getCountries();
      setCountries(countryList);
    };

    fetchData();

    setNewFirstName(user.firstName);
    setNewLastName(user.lastName);
    setNewGroupId(user.groupId);
    setGroupName(user.groupName);
    setGroupCountry(user.groupCountry);
    setNewHeadquarterId(user.headquarterId);
    setHeadquarterName(user.headquarterName);
    setNewRole(user.role);
  }, []);

  const updateUserData = async (
    userId: string,
    newFirstName: string,
    newLastName: string,
    newGroupId: string,
    newHeadquarterId: string,
    newRole: UserRole
  ) => {
    try {
      const response = await fetch(`${API_BASE_URL}/updateUserData`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId,
          newFirstName,
          newLastName,
          newGroupId,
          newHeadquarterId,
          newRole,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to update user data");
      }
    } catch (error) {
      console.error("Error update user data:", error);
      throw new Error("An error occurred while update user data");
    }
  };

  const handleRoleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const role = e.target.value as UserRole;
    setNewRole(role);

    if (role === UserRole.User || role === UserRole.GroupAdmin) {
      setNewHeadquarterId("");
    } else if (role === UserRole.HeadquarterAdmin) {
      setNewGroupId("");
      setGroupName("");
    } else if (role === UserRole.Guest) {
      setNewGroupId("");
      setGroupName("");
      setNewHeadquarterId("");
      setHeadquarterName("");
    }

    setSaveDataSubmitted(false);
  };

  const handleSaveDataClick = async () => {
    setSaveDataSubmitted(true);

    if (getFirstNameErrors(newFirstName) || getLastNameErrors(newLastName)) {
      return;
    }

    if (newRole === UserRole.User || newRole === UserRole.GroupAdmin) {
      if (newGroupId === "") return;
    } else if (newRole === UserRole.HeadquarterAdmin) {
      if (newHeadquarterId === "") return;
    }

    setShowChangeDataModal(false);

    await updateUserData(
      user.id,
      newFirstName,
      newLastName,
      newGroupId,
      newRole !== UserRole.HeadquarterAdmin &&
        user.groupId &&
        user.headquarterId
        ? ""
        : newHeadquarterId,
      newRole
    );

    const updatedUser: User = {
      id: user.id,
      firstName: newFirstName,
      lastName: newLastName,
      email: user.email,
      groupId: newGroupId,
      groupName: groupName,
      groupCountry: groupCountry,
      headquarterId: newHeadquarterId,
      headquarterName: headquarterName,
      role: newRole,
      isStreaming: user.isStreaming,
    };

    handleChangeData(updatedUser);
  };

  return (
    <div className="confirmation-modal">
      <div className="modal-content">
        <div style={{ textAlign: "center", fontSize: "1.3em" }}>Edit user</div>
        <div className="mb-3" style={{ marginTop: "2vh" }}>
          <label
            htmlFor="firstNameInput"
            style={{
              marginLeft: "20px",
              color: "#404040",
              fontWeight: "bold",
            }}
          >
            First Name
          </label>
          <input
            type="text"
            id="firstNameInput"
            className="form-control"
            value={newFirstName}
            onChange={(e) => {
              setNewFirstName(e.target.value);
              setSaveDataSubmitted(false);
            }}
            style={{
              backgroundColor: "#CCCCCC",
              borderRadius: "20px",
              color: "#404040",
              height: "5vh",
              maxHeight: "40px",
              fontSize: "1em",
              paddingLeft: "20px",
            }}
          />
          <label
            htmlFor="firstNameInput"
            style={{
              marginLeft: "20px",
              color: "#A63939",
              fontSize: "0.8em",
              visibility:
                saveDataSubmitted && getFirstNameErrors(newFirstName)
                  ? "visible"
                  : "hidden",
            }}
          >
            {getFirstNameErrors(newFirstName)}
          </label>
        </div>
        <div className="mb-3" style={{ marginTop: "2vh" }}>
          <label
            htmlFor="lastNameInput"
            style={{
              marginLeft: "20px",
              color: "#404040",
              fontWeight: "bold",
            }}
          >
            Last Name
          </label>
          <input
            type="text"
            id="lastNameInput"
            className="form-control"
            value={newLastName}
            onChange={(e) => {
              setNewLastName(e.target.value);
              setSaveDataSubmitted(false);
            }}
            style={{
              backgroundColor: "#CCCCCC",
              borderRadius: "20px",
              color: "#404040",
              height: "5vh",
              maxHeight: "40px",
              fontSize: "1em",
              paddingLeft: "20px",
            }}
          />
          <label
            htmlFor="lastNameInput"
            style={{
              marginLeft: "20px",
              color: "#A63939",
              fontSize: "0.8em",
              visibility:
                saveDataSubmitted && getLastNameErrors(newLastName)
                  ? "visible"
                  : "hidden",
            }}
          >
            {getLastNameErrors(newLastName)}
          </label>
        </div>
        {(adminView || headquarterView || groupView) && (
          <>
            <div className="mb-3" style={{ marginTop: "2vh" }}>
              <label
                htmlFor="roleSelect"
                style={{
                  marginLeft: "20px",
                  color: "#404040",
                  fontWeight: "bold",
                }}
              >
                Role
              </label>
              <select
                id="roleSelect"
                className="form-control"
                value={newRole}
                style={{
                  backgroundColor: "#CCCCCC",
                  borderRadius: "20px",
                  color: "#404040",
                  height: "5vh",
                  maxHeight: "40px",
                  fontSize: "1em",
                  paddingLeft: "20px",
                }}
                onChange={handleRoleChange}
              >
                {adminView && <option value="guest">Guest</option>}
                {(adminView || headquarterView || groupView) && (
                  <>
                    <option value="user">Hospital User</option>
                    <option value="groupAdmin">Hospital Admin</option>
                  </>
                )}
                {(adminView || headquarterView) && (
                  <option value="headquarterAdmin">Headquarter Admin</option>
                )}
              </select>
            </div>
          </>
        )}

        {(newRole === UserRole.User || newRole === UserRole.GroupAdmin) &&
          (adminView || headquarterView) && (
            <>
              <div className="mb-3" style={{ marginTop: "2vh" }}>
                <label
                  htmlFor="groupSelect"
                  style={{
                    marginLeft: "20px",
                    color: "#404040",
                    fontWeight: "bold",
                  }}
                >
                  Hospital
                </label>
                <select
                  id="groupSelect"
                  className="form-control"
                  value={newGroupId}
                  style={{
                    backgroundColor: "#CCCCCC",
                    borderRadius: "20px",
                    color: "#404040",
                    height: "5vh",
                    maxHeight: "40px",
                    fontSize: "1em",
                    paddingLeft: "20px",
                  }}
                  onChange={(e) => {
                    const selectedGroupId = e.target.value;
                    const selectedGroup = groups.find(
                      (group) => group.id === selectedGroupId
                    );
                    if (selectedGroup) {
                      const selectedGroupCountry = countries.find(
                        (country) => country.id === selectedGroup.countryId
                      );

                      setNewGroupId(selectedGroupId);
                      setGroupName(selectedGroup.name);
                      setGroupCountry(selectedGroupCountry.name);
                      setHeadquarterName(selectedGroup.headquarterName);
                      setSaveDataSubmitted(false);
                    }
                  }}
                >
                  <option value=""></option>
                  {groups.map((group: any) => (
                    <option key={group.id} value={group.id}>
                      {group.name}
                    </option>
                  ))}
                </select>
                <label
                  htmlFor="groupSelect"
                  style={{
                    marginLeft: "20px",
                    color: "#A63939",
                    fontSize: "0.8em",
                    visibility:
                      saveDataSubmitted &&
                      newGroupId === "" &&
                      (newRole === UserRole.User ||
                        newRole === UserRole.GroupAdmin)
                        ? "visible"
                        : "hidden",
                  }}
                >
                  Hospital is required
                </label>
              </div>
            </>
          )}

        {newRole === UserRole.HeadquarterAdmin && adminView && (
          <>
            <div className="mb-3" style={{ marginTop: "2vh" }}>
              <label
                htmlFor="headquarterSelect"
                style={{
                  marginLeft: "20px",
                  color: "#404040",
                  fontWeight: "bold",
                }}
              >
                Headquarter
              </label>
              <select
                id="headquarterSelect"
                className="form-control"
                value={newHeadquarterId}
                style={{
                  backgroundColor: "#CCCCCC",
                  borderRadius: "20px",
                  color: "#404040",
                  height: "5vh",
                  maxHeight: "40px",
                  fontSize: "1em",
                  paddingLeft: "20px",
                }}
                onChange={(e) => {
                  const selectedHeadquarterId = e.target.value;
                  const selectedHeadquarter = headquarters.find(
                    (headquarter) => headquarter.id === selectedHeadquarterId
                  );
                  if (selectedHeadquarter) {
                    setNewHeadquarterId(selectedHeadquarterId);
                    setHeadquarterName(selectedHeadquarter.name);
                    setSaveDataSubmitted(false);
                  }
                }}
              >
                <option value=""></option>
                {headquarters.map((headquarter: any) => (
                  <option key={headquarter.id} value={headquarter.id}>
                    {headquarter.name}
                  </option>
                ))}
              </select>
              <label
                htmlFor="headquarterSelect"
                style={{
                  marginLeft: "20px",
                  color: "#A63939",
                  fontSize: "0.8em",
                  visibility:
                    saveDataSubmitted &&
                    newHeadquarterId === "" &&
                    newRole === UserRole.HeadquarterAdmin
                      ? "visible"
                      : "hidden",
                }}
              >
                Headquarter is required
              </label>
            </div>
          </>
        )}

        <div className="button-container">
          <button
            type="button"
            className="buttonCancel btn btn-primary"
            onClick={() => {
              setShowChangeDataModal(false);
              setSaveDataSubmitted(false);
            }}
          >
            Cancel
          </button>
          <button
            type="button"
            className="buttonAcceptGreen btn btn-primary"
            onClick={() => handleSaveDataClick()}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChangeUserDataModal;

import React from "react";

const Footer: React.FC = () => {
  return (
    <footer
      style={{
        width: "100%",
        textAlign: "center",
        zIndex: 1,
        color: "#404040",
        fontFamily: "VirtualLabQuickSandLight",
      }}
    >
      <p>2024 © Copyright Virtual Lab Development, s.r.o.</p>
    </footer>
  );
};

export default Footer;

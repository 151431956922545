import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import pako from "pako";
import { useAuth } from "../firebase/auth.tsx";
import { API_BASE_URL } from "../config.tsx";

import { getUserDataById } from "./helpers/requests.tsx";

interface StreamProps {
  fullScreenMode: boolean;
  setFullScreenMode: (mode: boolean) => void;
}

const Stream: React.FC<StreamProps> = ({
  fullScreenMode,
  setFullScreenMode,
}) => {
  let IP = `${API_BASE_URL}/ws/`;
  let dataID_img = 0;
  let dataLength_img = 0;
  let receivedLength_img = 0;
  let dataByte_img = new Uint8Array(0);
  let ReadyToGetFrame_img = true;
  let isDesktopFrame = false;
  var label_img = 1001;
  var label_aud = 2001;
  var dataID_aud = 0;
  var dataLength_aud = 0;
  var receivedLength_aud = 0;
  var dataByte_aud = new Uint8Array(100);
  var ReadyToGetFrame_aud = true;

  var wsid = "null";

  let ws: WebSocket | null = null;
  const [serverConnected, setServerConnected] = useState(false);
  const [displayText, setDisplayText] = useState("Stream is offline");

  const { userId } = useAuth();

  const { streamingUserId } = useParams();

  const [streamingUserData, setStreamingUserData] = useState<any>([]);

  useEffect(() => {
    const fetchData = async () => {
      const userData = await getUserDataById(streamingUserId);
      setStreamingUserData(userData);
    };

    if (streamingUserId !== userId) fetchData();

    connectAsRoom();

    return () => {
      fmWebSocketDisconnect();
    };
  }, []);

  useEffect(() => {
    const handleFullScreenChange = () => {
      setFullScreenMode(!!document.fullscreenElement);
    };

    document.addEventListener("fullscreenchange", handleFullScreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullScreenChange);
    };
  }, []);

  const toggleFullScreen = () => {
    if (!fullScreenMode) {
      // Enter full screen
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      }
    } else {
      // Exit full screen
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
    setFullScreenMode(!fullScreenMode);
  };

  function FMWebSocketEvent(inputType, inputVariable) {
    ws.send("fmevent" + ":" + inputType + ":" + inputVariable);
  }

  function connectAsRoom() {
    if (ws != null) {
      fmWebSocketDisconnect();
    } else {
      fmWebSocketConnect("Room", streamingUserId);
    }
  }

  const fmWebSocketDisconnect = () => {
    if (ws !== null) {
      ws.close();
      ws = null;
    }
  };

  const fmWebSocketConnect = (inputNetworkType, inputRoomName) => {
    function RegisterRoom() {
      FMWebSocketEvent("roomName", inputRoomName);
    }

    function FMEventEncode(inputType, inputVariable) {
      return "fmevent" + ":" + inputType + ":" + inputVariable;
    }
    function FMEventDecode(inputString) {
      return inputString.split(":");
    }
    function OnMessageCheck(message) {
      //console.log("OnMessageCheck:" + message);
      if (message.includes("fmevent:")) {
        var decodedResult = FMEventDecode(message);
        var decodedType = decodedResult[1];
        var decodedValue = decodedResult[2];
        switch (decodedType) {
          case "OnReceivedWSIDEvent":
            wsid = decodedValue;
            break;
          case "OnJoinedLobbyEvent":
            RegisterRoom();
            break;
          case "OnLostServerEvent":
            //case "OnClientDisconnectedEvent":
            setServerConnected(false);
            setDisplayText("Stream is offline");
            break;
          case "OnFoundServerEvent":
            //case "OnClientConnectedEvent":
            setDisplayText("Loading...");
            break;
        }
      }
    }

    IP =
      IP.replace("http://", "ws://").replace("https://", "wss://") +
      streamingUserId;
    //console.log(IP);
    ws = new WebSocket(IP);
    ws.binaryType = "arraybuffer";

    function registerNetworkType() {
      FMWebSocketEvent("networkType", inputNetworkType);
    }

    ws.addEventListener("open", (event) => {
      registerNetworkType();
      console.log("**connected to server");
    });

    ws.onclose = function (evt) {
      console.log("**close");
    };

    ws.onerror = function (evt) {
      console.log("**error");
      //fmWebSocketDisconnect();
    };

    ws.onmessage = function (evt) {
      const data = evt.data;

      if (typeof data === "string") {
        OnMessageCheck(new String(evt.data));
      }

      if (data instanceof ArrayBuffer) {
        const _byteRaw = new Uint8Array(data);

        var _byteData;
        if (_byteRaw[1] === 3) {
          var _wsidByteLength = byteToInt16(_byteRaw, 4);
          _byteData = _byteRaw.slice(_wsidByteLength + 6, _byteRaw.length);
        } else {
          _byteData = _byteRaw.slice(4, _byteRaw.length);
        }

        // FMETP: first byte defines the whole data type..., 0 is raw, 1 is string
        if (_byteRaw[0] === 0) {
          if (_byteData.length > 18) {
            const _label = byteToInt16(_byteData, 0);

            if (_label == label_img) {
              // Process Image Data
              const _dataID = byteToInt16(_byteData, 2);
              if (_dataID !== dataID_img) receivedLength_img = 0;
              dataID_img = _dataID;
              dataLength_img = byteToInt32(_byteData, 4);
              const _offset = byteToInt32(_byteData, 8);
              const _GZipMode = _byteData[12] === 1 ? true : false;

              isDesktopFrame = _byteData[14] === 1 ? true : false;
              const metaByteLength = isDesktopFrame ? 24 : 15;

              if (receivedLength_img === 0) {
                dataByte_img = new Uint8Array(dataLength_img);
              }
              const chunkLength = _byteData.length - metaByteLength;
              if (_offset + chunkLength <= dataByte_img.length) {
                //----------------add byte----------------
                receivedLength_img += chunkLength;
                dataByte_img = combineInt8Array(
                  dataByte_img,
                  _byteData.slice(metaByteLength, _byteData.length),
                  _offset
                );
                //----------------add byte----------------
              }

              if (
                ReadyToGetFrame_img &&
                receivedLength_img === dataLength_img
              ) {
                processImageData(dataByte_img, _GZipMode);

                if (!serverConnected) {
                  setServerConnected(true);
                }
              }
            } else if (_label == label_aud) {
              // Process Audio Data
              const _dataID = byteToInt32(_byteData, 2);
              if (_dataID !== dataID_aud) receivedLength_aud = 0;

              dataID_aud = _dataID;
              dataLength_aud = byteToInt32(_byteData, 4);
              const _offset = byteToInt32(_byteData, 8);
              const _GZipMode = _byteData[12] === 1 ? true : false;

              if (receivedLength_aud === 0) {
                dataByte_aud = new Uint8Array(dataLength_aud);
              }
              receivedLength_aud += _byteData.length - 14;
              //----------------add byte----------------
              dataByte_aud = combineInt8Array(
                dataByte_aud,
                _byteData.slice(14, _byteData.length),
                _offset
              );
              //----------------add byte----------------

              if (
                ReadyToGetFrame_aud &&
                receivedLength_aud === dataLength_aud
              ) {
                processAudioData(dataByte_aud, _GZipMode);
              }
            }
          }
        }
      }
    };

    const processImageData = (_byte: Uint8Array, _GZipMode: boolean) => {
      ReadyToGetFrame_img = false;
      let binary = "";
      var bytes = new Uint8Array(_byte);
      let foundError = false;

      bytes = new Uint8Array(_byte);

      if (_GZipMode) {
        try {
          bytes = pako.inflate(bytes);
        } catch (error) {
          console.error(error);
          foundError = true;
          ReadyToGetFrame_img = true;
        }
      }

      if (!foundError) {
        //----conver byte[] to Base64 string----
        let len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
          binary += String.fromCharCode(bytes[i]);
        }
        //----conver byte[] to Base64 string----

        const img = document.getElementById("DisplayImg") as HTMLImageElement;
        if (img !== null) img.src = "data:image/jpeg;base64," + btoa(binary);

        ReadyToGetFrame_img = true;
      }
    };

    function processAudioData(_byte, _GZipMode) {
      ReadyToGetFrame_aud = false;
      let bytes = new Uint8Array(_byte);

      if (_GZipMode) {
        bytes = pako.inflate(bytes);
      }

      // Read meta data
      const sourceSampleRate = byteToInt32(bytes, 0);
      const sourceChannels = byteToInt32(bytes, 4);

      // Convert byte[] to float
      const bufferData = bytes.slice(8, bytes.length);
      const audioInt16 = new Int16Array(bufferData.buffer);

      // Playback audio
      if (audioInt16.length > 0) {
        streamAudio(
          sourceChannels,
          audioInt16.length,
          sourceSampleRate,
          audioInt16
        );
      }

      ReadyToGetFrame_aud = true;
    }

    // Initialize audio context
    let startTime = 0;
    const audioCtx = new AudioContext();

    function streamAudio(numChannels, numSamples, sampleRate, audioChunks) {
      const audioBuffer = audioCtx.createBuffer(
        numChannels,
        numSamples / numChannels,
        sampleRate
      );

      for (let channel = 0; channel < numChannels; channel++) {
        // This gives us the actual ArrayBuffer that contains the data
        const nowBuffering = audioBuffer.getChannelData(channel);
        for (var i = 0; i < numSamples; i++) {
          var order = i * numChannels + channel;
          var localSample = 1.0 / 32767.0;
          localSample *= audioChunks[order];
          nowBuffering[i] = localSample;
        }
      }

      const source = audioCtx.createBufferSource();
      source.buffer = audioBuffer;
      source.connect(audioCtx.destination);
      source.start(startTime);
      startTime += audioBuffer.duration;
    }
  };

  function combineInt8Array(
    a: Uint8Array,
    b: Uint8Array,
    offset: number
  ): Uint8Array {
    const c = new Uint8Array(a.length);
    c.set(a);
    c.set(b, offset);
    return c;
  }

  function combineFloat32Array(a, b) {
    var c = new Float32Array(a.length + b.length);
    c.set(a);
    c.set(b, a.length);
    return c;
  }

  const byteToInt32 = (_byte: Uint8Array, _offset: number): number => {
    return (
      (_byte[_offset] & 255) +
      ((_byte[_offset + 1] & 255) << 8) +
      ((_byte[_offset + 2] & 255) << 16) +
      ((_byte[_offset + 3] & 255) << 24)
    );
  };

  const byteToInt16 = (_byte: Uint8Array, _offset: number): number => {
    return (_byte[_offset] & 255) + ((_byte[_offset + 1] & 255) << 8);
  };

  const hideMissedIcon = (elem: HTMLElement) => {
    const alt = elem.getAttribute("alt") || "";

    const altTextNode = document.createTextNode(alt);

    if (elem.parentNode) {
      elem.parentNode.insertBefore(altTextNode, elem);
      elem.parentNode.removeChild(elem);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height:
          fullScreenMode && serverConnected ? "100%" : "calc(100vh - 81px)",
        paddingTop: fullScreenMode && serverConnected ? "0px" : "140px",
        paddingBottom: fullScreenMode && serverConnected ? "0px" : "20px",
        paddingLeft: fullScreenMode && serverConnected ? "0px" : "20px",
        paddingRight: fullScreenMode && serverConnected ? "0px" : "20px",
      }}
    >
      {(!fullScreenMode || !serverConnected) && (
        <>
          {streamingUserId &&
            streamingUserId !== userId &&
            streamingUserData.email && (
              <div style={{ marginTop: "1vh" }}>
                <span style={{ color: "#39a686" }}>Stream for user:</span>
                {` ${streamingUserData.firstName} ${streamingUserData.lastName} `}
                <span style={{ fontFamily: "VirtualLabQuickSandLight" }}>
                  {` | ${streamingUserData.email} `}
                  {streamingUserData.groupName &&
                    ` | ${streamingUserData.groupName}`}
                  {streamingUserData.groupCountry &&
                    ` | ${streamingUserData.groupCountry.name}`}
                </span>
              </div>
            )}
          <div
            style={{
              backgroundColor: "#39A686",
              width: "80vh",
              paddingBottom: "3px",
            }}
          ></div>
        </>
      )}
      <div
        style={{
          textAlign: "center",
          margin: fullScreenMode && serverConnected ? "0px" : "20px auto",
          height:
            fullScreenMode && serverConnected
              ? "90vh"
              : serverConnected
              ? "100%"
              : "auto",
          width: "100%",
        }}
      >
        {serverConnected ? (
          <img
            id="DisplayImg"
            style={{
              height: fullScreenMode && serverConnected ? "100vh" : "100%",
            }}
            alt=""
            src=""
          />
        ) : (
          <span>
            <img
              id="DisplayImg"
              width="0%"
              alt=""
              src=""
              onError={(e) => {
                hideMissedIcon(e.currentTarget);
              }}
            />
            {displayText}
          </span>
        )}
      </div>
      {(!fullScreenMode || !serverConnected) && (
        <div
          style={{
            backgroundColor: "#39A686",
            width: "80vh",
            paddingTop: "3px",
          }}
        ></div>
      )}
      {serverConnected && !fullScreenMode && (
        <button
          className="btn btn-primary mb-2"
          style={{
            height: "5vh",
            maxHeight: "40px",
            maxWidth: "20%",
            minWidth: "10%",
            borderRadius: "20px",
            marginTop: "20px",
            backgroundColor: "#39A686",
            borderColor: "#39A686",
          }}
          onClick={toggleFullScreen}
        >
          Enter Full Screen
        </button>
      )}
    </div>
  );
};

export default Stream;

import React from "react";

const DefaultPageView: React.FC = () => {
  return (
    <div style={{ position: "relative", width: "100%", height: "100%" }}>
      <img
        src="/images/Background_Element_TopLeft.png"
        alt="Top Left Image"
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          maxWidth: "20%",
          width: "auto",
          maxHeight: "20vh",
          height: "auto",
          zIndex: -1,
        }}
      />
      <img
        src="/images/Background_Element_BottomRight.png"
        alt="Bottom Right Image"
        style={{
          position: "fixed",
          bottom: 0,
          right: 0,
          maxWidth: "20%",
          width: "auto",
          maxHeight: "20vh",
          height: "auto",
          zIndex: -1,
        }}
      />
    </div>
  );
};

export default DefaultPageView;

import React, { useEffect, useState } from "react";
import { useAuth } from "../../firebase/auth.tsx";
import {
  isEmailValid,
  getPasswordErrors,
  getFirstNameErrors,
  getLastNameErrors,
} from "../helpers/helpers.tsx";
import {
  getGroups,
  getHeadquarters,
  getGroupsInHeadquarter,
  getHeadquarterNameByGroupId,
} from "../helpers/requests.tsx";

const CreateAccountModal = ({
  setShowCreateAccountModal,
  groupId,
  headquarterId,
  userRole,
  adminView = false,
  headquarterView = false,
  groupView = false,
  handleCreateAccount,
}) => {
  const { register } = useAuth();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const [groups, setGroups] = useState<any[]>([]);
  const [headquarters, setHeadquarters] = useState<any[]>([]);
  const [selectedUserRole, setSelectedUserRole] = useState("guest");
  const [selectedGroup, setSelectedGroup] = useState("");
  const [selectedHeadquarter, setSelectedHeadquarter] = useState("");

  useEffect(() => {
    setSelectedUserRole(userRole);
    setSelectedGroup(groupId);
    setSelectedHeadquarter(headquarterId);

    const fetchData = async () => {
      let groupsList = [];
      if (adminView) {
        groupsList = await getGroups();
        setGroups(groupsList);

        const headquarterList = await getHeadquarters();
        setHeadquarters(headquarterList);
      } else if (headquarterView) {
        groupsList = await getGroupsInHeadquarter(headquarterId);
        setGroups(groupsList);
      }
    };

    fetchData();
  }, []);

  const handleCreateAccountSubmit = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();

    setFormSubmitted(true);

    if (
      !isEmailValid(email) ||
      getPasswordErrors(password, confirmPassword) ||
      getFirstNameErrors(firstName) ||
      getLastNameErrors(lastName) ||
      ((selectedUserRole === "user" || selectedUserRole === "groupAdmin") &&
        !selectedGroup) ||
      (selectedUserRole === "headquarterAdmin" && !selectedHeadquarter)
    ) {
      return;
    }

    // FIXME: In current version there are 2 fields in USERS database: "groupId" and "headquarterId"
    // If user is headquarter admin - headquarterId will be ID of headquarter and groupId will be empty
    // If user is group admin - headquarterId will be empty and groupId will be ID of group
    // That's not a good approach as gets confused when group is in headquarter. Change it later
    // Also on server side SIGNUP function have to make additional requests to database to get headquarterName that's not good
    let newGroupId = selectedGroup;
    let newHeadquarterId = selectedHeadquarter;

    const newHeadquarterName = newGroupId
      ? await getHeadquarterNameByGroupId(newGroupId)
      : "";

    if (selectedUserRole === "headquarterAdmin") {
      newGroupId = "";
    } else if (
      selectedUserRole === "user" ||
      selectedUserRole === "groupAdmin"
    ) {
      newHeadquarterId = "";
    }

    const newUser = await register(
      email,
      password,
      firstName,
      lastName,
      newGroupId,
      newHeadquarterId,
      selectedUserRole
    );

    // FIXME
    newUser.headquarterName = newHeadquarterName;

    setShowCreateAccountModal(false);
    handleCreateAccount(newUser);
  };

  return (
    <div className="confirmation-modal">
      <div className="modal-content">
        <div
          style={{ textAlign: "center", fontSize: "1.3em", marginTop: "2vh" }}
        >
          Create new user
        </div>
        <form onSubmit={handleCreateAccountSubmit} className="m-4">
          <div className="mb-3">
            <label
              htmlFor="emailInput"
              style={{
                marginLeft: "20px",
                color: "#404040",
                fontWeight: "bold",
              }}
            >
              Email
            </label>
            <input
              className="form-control"
              id="emailInput"
              placeholder="example@site.com"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setFormSubmitted(false);
              }}
              style={{
                backgroundColor: "#CCCCCC",
                borderRadius: "20px",
                color: "#404040",
                height: "5vh",
                maxHeight: "40px",
                fontSize: "1em",
                paddingLeft: "20px",
              }}
            />
            <label
              htmlFor="emailInput"
              style={{
                marginLeft: "20px",
                color: "#A63939",
                fontSize: "0.8em",
                visibility:
                  formSubmitted && !isEmailValid(email) ? "visible" : "hidden",
              }}
            >
              This email is invalid
            </label>
          </div>
          <div className="mb-3" style={{ marginTop: "2vh" }}>
            <label
              htmlFor="passwordInput"
              style={{
                marginLeft: "20px",
                color: "#404040",
                fontWeight: "bold",
              }}
            >
              Password
            </label>
            <input
              type={showPassword ? "text" : "password"}
              className="form-control"
              id="passwordInput"
              placeholder="Minimum 6 characters"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                setFormSubmitted(false);
              }}
              style={{
                backgroundColor: "#CCCCCC",
                borderRadius: "20px",
                color: "#404040",
                height: "5vh",
                maxHeight: "40px",
                fontSize: "1em",
                paddingLeft: "20px",
              }}
            />
          </div>
          <div>
            <input
              type={showPassword ? "text" : "password"}
              className="form-control"
              id="passwordConfirmInput"
              placeholder="Confirm password"
              value={confirmPassword}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
                setFormSubmitted(false);
              }}
              style={{
                backgroundColor: "#CCCCCC",
                borderRadius: "20px",
                color: "#404040",
                height: "5vh",
                maxHeight: "40px",
                fontSize: "1em",
                paddingLeft: "20px",
              }}
            />
          </div>
          <label
            htmlFor="passwordInput"
            style={{
              marginLeft: "20px",
              color: "#A63939",
              fontSize: "0.8em",
              visibility:
                formSubmitted && getPasswordErrors(password, confirmPassword)
                  ? "visible"
                  : "hidden",
            }}
          >
            {getPasswordErrors(password, confirmPassword)}
          </label>
          <div className="mb-3 form-check custom-checkbox">
            <label
              htmlFor="showPasswordCheckbox"
              className="custom-checkbox-label"
            >
              <input
                type="checkbox"
                className="form-check-input"
                id="showPasswordCheckbox"
                checked={showPassword}
                onChange={() => setShowPassword(!showPassword)}
              />
              <span className="checkmark"></span>
              <span
                className="form-check-label"
                style={{
                  fontFamily: "VirtualLabQuickSandLight",
                  fontSize: "0.8em",
                  fontWeight: "bold",
                  marginTop: "7px",
                }}
              >
                Show Password
              </span>
            </label>
          </div>
          <div className="mb-3" style={{ marginTop: "2vh" }}>
            <label
              htmlFor="firstNameInput"
              style={{
                marginLeft: "20px",
                color: "#404040",
                fontWeight: "bold",
              }}
            >
              First Name
            </label>
            <input
              type="text"
              className="form-control"
              id="firstNameInput"
              placeholder="John"
              value={firstName}
              onChange={(e) => {
                setFirstName(e.target.value);
                setFormSubmitted(false);
              }}
              style={{
                backgroundColor: "#CCCCCC",
                borderRadius: "20px",
                color: "#404040",
                height: "5vh",
                maxHeight: "40px",
                fontSize: "1em",
                paddingLeft: "20px",
              }}
            />
            <label
              htmlFor="firstNameInput"
              style={{
                marginLeft: "20px",
                color: "#A63939",
                fontSize: "0.8em",
                visibility:
                  formSubmitted && getFirstNameErrors(firstName)
                    ? "visible"
                    : "hidden",
              }}
            >
              {getFirstNameErrors(firstName)}
            </label>
          </div>
          <div className="mb-3" style={{ marginTop: "2vh" }}>
            <label
              htmlFor="lastNameInput"
              style={{
                marginLeft: "20px",
                color: "#404040",
                fontWeight: "bold",
              }}
            >
              Last Name
            </label>
            <input
              type="text"
              className="form-control"
              id="lastNameInput"
              placeholder="Smith"
              value={lastName}
              onChange={(e) => {
                setLastName(e.target.value);
                setFormSubmitted(false);
              }}
              style={{
                backgroundColor: "#CCCCCC",
                borderRadius: "20px",
                color: "#404040",
                height: "5vh",
                maxHeight: "40px",
                fontSize: "1em",
                paddingLeft: "20px",
              }}
            />
            <label
              htmlFor="lastNameInput"
              style={{
                marginLeft: "20px",
                color: "#A63939",
                fontSize: "0.8em",
                visibility:
                  formSubmitted && getLastNameErrors(lastName)
                    ? "visible"
                    : "hidden",
              }}
            >
              {getLastNameErrors(lastName)}
            </label>
          </div>
          {(adminView || headquarterView || groupView) && (
            <>
              <div className="mb-3" style={{ marginTop: "2vh" }}>
                <label
                  htmlFor="userRoleSelect"
                  style={{
                    marginLeft: "20px",
                    color: "#404040",
                    fontWeight: "bold",
                  }}
                >
                  Role
                </label>
                <select
                  id="userRoleSelect"
                  className="form-control"
                  style={{
                    backgroundColor: "#CCCCCC",
                    borderRadius: "20px",
                    color: "#404040",
                    height: "5vh",
                    maxHeight: "40px",
                    fontSize: "1em",
                    paddingLeft: "20px",
                  }}
                  value={selectedUserRole}
                  onChange={(e) => {
                    setSelectedUserRole(e.target.value);
                    setFormSubmitted(false);
                  }}
                >
                  {adminView && <option value="guest">Guest</option>}
                  {(adminView || headquarterView || groupView) && (
                    <>
                      <option value="user">Hospital User</option>
                      <option value="groupAdmin">Hospital Admin</option>
                    </>
                  )}
                  {(adminView || headquarterView) && (
                    <option value="headquarterAdmin">Headquarter Admin</option>
                  )}
                </select>
              </div>
            </>
          )}

          {(selectedUserRole === "user" || selectedUserRole === "groupAdmin") &&
            (adminView || headquarterView) && (
              <div className="mb-3" style={{ marginTop: "2vh" }}>
                <label
                  htmlFor="groupSelect"
                  style={{
                    marginLeft: "20px",
                    color: "#404040",
                    fontWeight: "bold",
                  }}
                >
                  Select hospital
                </label>
                <select
                  id="groupSelect"
                  className="form-control"
                  style={{
                    backgroundColor: "#CCCCCC",
                    borderRadius: "20px",
                    color: "#404040",
                    height: "5vh",
                    maxHeight: "40px",
                    fontSize: "1em",
                    paddingLeft: "20px",
                  }}
                  value={selectedGroup}
                  onChange={(e) => {
                    setSelectedGroup(e.target.value);
                    setFormSubmitted(false);
                  }}
                >
                  <option value=""></option>
                  {groups.map((group: any) => (
                    <option key={group.id} value={group.id}>
                      {group.name}
                    </option>
                  ))}
                </select>
                <label
                  htmlFor="groupSelect"
                  style={{
                    marginLeft: "20px",
                    color: "#A63939",
                    fontSize: "0.8em",
                    visibility:
                      formSubmitted && selectedGroup === ""
                        ? "visible"
                        : "hidden",
                  }}
                >
                  Hospital is required
                </label>
              </div>
            )}
          {selectedUserRole === "headquarterAdmin" && adminView && (
            <div className="mb-3" style={{ marginTop: "2vh" }}>
              <label
                htmlFor="headquarterSelect"
                style={{
                  marginLeft: "20px",
                  color: "#404040",
                  fontWeight: "bold",
                }}
              >
                Select headquarter
              </label>
              <select
                id="headquarterSelect"
                className="form-control"
                value={selectedHeadquarter}
                onChange={(e) => {
                  setSelectedHeadquarter(e.target.value);
                  setFormSubmitted(false);
                }}
                style={{
                  backgroundColor: "#CCCCCC",
                  borderRadius: "20px",
                  color: "#404040",
                  height: "5vh",
                  maxHeight: "40px",
                  fontSize: "1em",
                  paddingLeft: "20px",
                }}
              >
                <option value=""></option>
                {headquarters.map((headquarter: any) => (
                  <option key={headquarter.id} value={headquarter.id}>
                    {headquarter.name}
                  </option>
                ))}
              </select>
              <label
                htmlFor="headquarterSelect"
                style={{
                  marginLeft: "20px",
                  color: "#A63939",
                  fontSize: "0.8em",
                  visibility:
                    formSubmitted && selectedHeadquarter === ""
                      ? "visible"
                      : "hidden",
                }}
              >
                Headquarter is required
              </label>
            </div>
          )}
          <div className="button-container">
            <button
              type="button"
              className="buttonCancel btn btn-primary"
              onClick={() => setShowCreateAccountModal(false)}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn btn-primary buttonAcceptGreen"
              disabled={formSubmitted}
            >
              Create account
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateAccountModal;

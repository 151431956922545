import "../../styles/admin.css";

const ConfirmHideModal = ({
  setShowConfirmHideModal,
  manufacturerId,
  manufacturerName,
  headquarterView = false,
  groupsView = false,
  usersView = false,
  handleHideClick,
}) => {
  let text = "";
  if (headquarterView) text = "for all users in the headquarter";
  else if (groupsView) text = "for all users in the group";
  else if (usersView) text = "for selected user";

  return (
    <div className="confirmation-modal">
      <div className="modal-content">
        <div
          style={{ textAlign: "center", fontSize: "1.3em", marginTop: "2vh" }}
        >
          Confirm to hide manufacturer
        </div>
        <label style={{ lineHeight: "1", marginTop: "2vh" }}>
          This is irreversible action.
          <br />
          All manufacturer trainings will be deactivated and hided {text}
        </label>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "5vh",
            maxHeight: "1%",
          }}
        >
          <img
            src="/images/Icon_HQ.png"
            style={{
              width: "5vh",
              minWidth: "25px",
              marginRight: "1vh",
            }}
          />
          <div className="userDataWrapper">{manufacturerName}</div>
        </div>

        <div className="button-container">
          <button
            type="button"
            className="buttonCancel btn btn-primary"
            onClick={() => setShowConfirmHideModal(false)}
          >
            Cancel
          </button>
          <button
            type="button"
            className="buttonAccept btn btn-primary"
            onClick={() => handleHideClick(manufacturerId)}
          >
            Hide
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmHideModal;

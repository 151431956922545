import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../config.tsx";
import { checkForAdmin } from "./helpers/requests.tsx";

import "../App.css";

const PageMenu = ({
  userId,
  userRole,
  firstName,
  lastName,
  email,
  headquarterId,
  groupId,
  logout,
  fullScreenMode,
}) => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [isHeadquarterAdmin, setIsHeadquarterAdmin] = useState(false);
  const [isGroupAdmin, setIsGroupAdmin] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (!email || !userRole) {
      navigate("/login");
      return;
    }

    const fetchData = async () => {
      const result = await checkForAdmin(userId);

      if (result.admin) setIsAdmin(result.admin);
      else if (result.headquarterAdmin)
        setIsHeadquarterAdmin(result.headquarterAdmin);
      else if (result.groupAdmin) setIsGroupAdmin(result.groupAdmin);
    };

    fetchData();
  }, []);

  const handleStreamClick = () => {
    // Force a reload to the stream route
    navigate(`/stream/${userId}`, { replace: true });
    window.location.reload();
  };

  return (
    <div>
      {!fullScreenMode && (
        <div
          style={{
            backgroundColor: "#39A686",
            borderRadius: "10px",
            marginTop: "16px",
            padding: "12px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            position: "fixed",
            top: "0",
            left: "50%",
            transform: "translateX(-50%)",
            zIndex: "11",
            width: "96%",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src="/images/VR_ICU_Logo_Small.png"
              alt="Logo"
              style={{
                width: "8vh",
                minWidth: "50px",
                marginLeft: "10px",
              }}
            />
            <Link
              to="/main"
              style={{
                color: "white",
                marginLeft: "50px",
                textDecoration: "none",
              }}
            >
              Training reports
            </Link>
            <a
              onClick={handleStreamClick}
              style={{
                color: "white",
                marginLeft: "50px",
                textDecoration: "none",
                cursor: "pointer",
              }}
            >
              Stream
            </a>
            {isAdmin && (
              <>
                <span
                  style={{
                    width: "1px",
                    height: "20px",
                    backgroundColor: "white",
                    marginLeft: "45px",
                  }}
                ></span>
                <Link
                  to="/headquarterList"
                  style={{
                    color: "white",
                    marginLeft: "45px",
                    textDecoration: "none",
                  }}
                >
                  Headquarter list
                </Link>
                <Link
                  to="/groups"
                  style={{
                    color: "white",
                    marginLeft: "45px",
                    textDecoration: "none",
                  }}
                >
                  Hospital list
                </Link>
                <Link
                  to="/allUsers"
                  style={{
                    color: "white",
                    marginLeft: "45px",
                    textDecoration: "none",
                  }}
                >
                  User list
                </Link>
              </>
            )}
            {isGroupAdmin && (
              <>
                <div
                  style={{
                    width: "1px",
                    height: "20px",
                    backgroundColor: "white",
                    marginLeft: "45px",
                  }}
                ></div>
                <Link
                  to={`/userList/group/${groupId}`}
                  style={{
                    color: "white",
                    marginLeft: "45px",
                    textDecoration: "none",
                  }}
                >
                  Hospital
                </Link>
              </>
            )}
            {isHeadquarterAdmin && (
              <>
                <div
                  style={{
                    width: "1px",
                    height: "20px",
                    backgroundColor: "white",
                    marginLeft: "45px",
                  }}
                ></div>
                <Link
                  to={`/groupList/headquarter/${headquarterId}`}
                  style={{
                    color: "white",
                    marginLeft: "45px",
                    textDecoration: "none",
                  }}
                >
                  Headquarter
                </Link>
              </>
            )}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                marginRight: "10px",
                color: "white",
                lineHeight: "1",
              }}
            >
              <div
                style={{
                  textAlign: "right",
                  paddingBottom: "3px",
                }}
              >
                {firstName} {lastName}
              </div>
              <div
                style={{
                  textAlign: "right",
                  fontFamily: "VirtualLabQuickSandLight",
                }}
              >
                {email}
              </div>
            </div>
            <img
              src="/images/Icon_User.png"
              alt="Logo"
              style={{
                width: "8%",
                marginRight: "20px",
              }}
            />
            <button
              onClick={logout}
              style={{
                backgroundColor: "transparent",
                marginRight: "20px",
                border: "none",
                color: "white",
                cursor: "pointer",
              }}
            >
              Log out
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PageMenu;

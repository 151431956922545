export interface User {
  id?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  groupId?: string;
  groupName?: string;
  groupCountry?: string;
  headquarterId?: string;
  headquarterName?: string;
  role?: UserRole;
  isStreaming?: boolean;
}

export enum UserRole {
  Admin = "admin",
  HeadquarterAdmin = "headquarterAdmin",
  GroupAdmin = "groupAdmin",
  User = "user",
  Guest = "guest",
}

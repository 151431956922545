import { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../firebase/auth.tsx";
import CreateAccountModal from "./modals/createAccountModal.tsx";
import ConfirmDeleteGroupModal from "./modals/confirmDeleteGroupModal.tsx";
import ChangeGroupDataModal from "./modals/changeGroupDataModal.tsx";
import {
  checkForAdmin,
  getHeadquarterNameById,
  getGroupNameById,
  getHeadquarterNameByGroupId,
  getCountryDataByGroupId,
} from "./helpers/requests.tsx";
import { correctUserRoleName } from "./helpers/helpers.tsx";

const GroupMenu = ({ updateUserList }) => {
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const params = useParams();
  const { headquarterId, groupId } = params;

  const navigate = useNavigate();
  const { userId, userRole } = useAuth();

  const [headquarterName, setHeadquarterName] = useState("");
  const [groupName, setGroupName] = useState("");
  const [countryId, setCountryId] = useState("");
  const [countryName, setCountryName] = useState("");
  const [showAdminBlock, setShowAdminBlock] = useState(false);
  const [showChangeDataModal, setShowChangeDataModal] = useState(false);
  const [showCreateGroupAccountModal, setShowCreateGroupAccountModal] =
    useState(false);
  const [showConfirmDeleteGroupModal, setShowConfirmDeleteGroupModal] =
    useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const isAdmin = await checkForAdmin(userId);
      setShowAdminBlock(isAdmin.admin || isAdmin.headquarterAdmin);

      const headquarterData = headquarterId
        ? await getHeadquarterNameById(headquarterId)
        : await getHeadquarterNameByGroupId(groupId);

      const groupData = await getGroupNameById(groupId);
      setHeadquarterName(headquarterData);
      setGroupName(groupData);
      const countryData = await getCountryDataByGroupId(groupId);
      setCountryId(countryData.id);
      setCountryName(countryData.name);
    };

    fetchData();

    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOptionsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOptionsOpen(!isOptionsOpen);
  };

  const handleChangeDataClick = () => {
    setIsOptionsOpen(false);
    setShowChangeDataModal(true);
  };

  const handleCreateGroupAccountClick = () => {
    setIsOptionsOpen(false);
    setShowCreateGroupAccountModal(true);
  };

  const handleDeleteGroupClick = () => {
    setIsOptionsOpen(false);
    setShowConfirmDeleteGroupModal(true);
  };

  const handleCreateGroupAccount = (newAccount: any) => {
    updateUserList(newAccount);
  };

  const handleDeleteGroup = async () => {
    navigate(`/groupList/headquarter/${headquarterId}`);
  };

  const handleChangeData = async (
    newGroupName: string,
    newGroupCountryId: string,
    newGroupCountryName: string
  ) => {
    setGroupName(newGroupName);
    setCountryId(newGroupCountryId);
    setCountryName(newGroupCountryName);
    setShowChangeDataModal(false);
  };

  const renderChangeDataModal = () => {
    return (
      <div className={"adminContainer"}>
        <ChangeGroupDataModal
          setShowChangeDataModal={setShowChangeDataModal}
          groupId={groupId}
          groupName={groupName}
          groupCountryId={countryId}
          groupCountryName={countryName}
          headquarterId={headquarterId}
          handleChangeData={handleChangeData}
        />
      </div>
    );
  };

  const renderCreateGroupAccountModal = () => {
    return (
      <div className={"adminContainer"}>
        <CreateAccountModal
          setShowCreateAccountModal={setShowCreateGroupAccountModal}
          groupId={groupId}
          headquarterId={headquarterId}
          userRole={"user"}
          groupView={true}
          handleCreateAccount={handleCreateGroupAccount}
        />
      </div>
    );
  };

  const renderConfirmDeleteGroupModal = () => {
    return (
      <div className={"adminContainer"}>
        <ConfirmDeleteGroupModal
          setShowConfirmDeleteGroupModal={setShowConfirmDeleteGroupModal}
          groupId={groupId}
          groupName={groupName}
          groupCountry={countryName}
          handleDeleteGroupSubmit={handleDeleteGroup}
        />
      </div>
    );
  };

  return (
    <>
      <div
        style={{
          backgroundColor: "#D4E5E2",
          borderRadius: "10px",
          marginTop: "86px",
          marginLeft: "50px",
          marginRight: "50px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          height: "70px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            src="/images/Icon_Hospital.png"
            style={{
              width: "6vh",
              minWidth: "50px",
              marginLeft: "20px",
            }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              marginLeft: "20px",
              color: "white",
              lineHeight: "1",
            }}
          >
            <div
              style={{
                textAlign: "left",
                paddingBottom: "8px",
                color: "#39a686",
                fontSize: "1.5em",
              }}
            >
              {groupName}{" "}
              <span style={{ fontFamily: "VirtualLabQuickSandMedium" }}>
                | {countryName}
              </span>
            </div>
            <div
              style={{
                textAlign: "left",
                fontFamily: "VirtualLabQuickSandBold",
                color: "#212529",
                fontSize: "1.1em",
              }}
            >
              Your role:{" "}
              {
                <span style={{ fontFamily: "VirtualLabQuickSandMedium" }}>
                  {correctUserRoleName(userRole)}
                </span>
              }
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            marginRight: "20px",
          }}
        >
          <div className="dropdown" ref={dropdownRef}>
            <button
              type="button"
              className="btn btn-primary"
              onClick={toggleDropdown}
              style={{
                backgroundColor: "#39A686",
                borderColor: "#39A686",
                borderRadius: "10px",
                fontSize: "1em",
              }}
            >
              Options
            </button>
            {isOptionsOpen && (
              <div className="dropdownMenu">
                {showAdminBlock && (
                  <button
                    type="button"
                    className="btn"
                    onClick={handleChangeDataClick}
                  >
                    <img src="/images/Icon_Edit.png" />
                    Edit hospital
                  </button>
                )}
                <button
                  onClick={handleCreateGroupAccountClick}
                  type="button"
                  className="btn"
                >
                  <img src="/images/Icon_AddUser.png" />
                  Create new user account
                </button>
                {showAdminBlock && (
                  <button
                    onClick={handleDeleteGroupClick}
                    type="button"
                    className="btn"
                  >
                    <img src="/images/Icon_Delete.png" />
                    Delete hospital
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {(showChangeDataModal && renderChangeDataModal()) ||
        (showConfirmDeleteGroupModal && renderConfirmDeleteGroupModal()) ||
        (showCreateGroupAccountModal && renderCreateGroupAccountModal())}
    </>
  );
};

export default GroupMenu;

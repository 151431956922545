import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { checkForAdmin } from "./helpers/requests.tsx";
import { useAuth } from "../firebase/auth.tsx";

export const AdminRoute = ({ children }) => {
  const { userId } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const isAdmin = await checkForAdmin(userId);
        if (!isAdmin.admin) {
          navigate("/main");
        }
      } catch (error) {
        console.error("Error checking admin permissions:", error);
      }
    };

    fetchData();
  }, [userId, navigate]);

  return <>{children}</>;
};

import { Navigate } from "react-router-dom";
import { useAuth } from "../firebase/auth.tsx";

export const DefaultRoute = ({ children }) => {
  const { userRole } = useAuth();

  if (userRole) {
    return <Navigate to="/main" />;
  }

  return children;
};
